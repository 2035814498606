import { type ElementType, type PropsWithChildren, type ReactNode, Fragment } from 'react';
import { type JSONContent } from '@tiptap/core';
import { uniqueId } from 'lodash';
import sanitize from 'sanitize-html';

import Link from '@/components/common/Link';
import Button from '@/components/common/Button';

const COMPONENTS: Record<string, ElementType> = {
  blockquote: 'blockquote',
  bulletList: 'ul',
  button: ({ children, href }: PropsWithChildren<{ href: string }>) => (
    <div>
      <Button className="font-medium" padding="sm" color="primary" href={encodeURI(href)} external>
        {children}
      </Button>
    </div>
  ),
  doc: Fragment,
  hardBreak: 'br',
  horizontalRule: 'hr',
  link: Link,
  listItem: 'li',
  orderedList: 'ol',
  paragraph: 'p',
  subtitle: 'h3',
  text: 'span',
  title: 'h2',
};

export function serializeToJSX(
  node?: JSONContent | null,
  components?: { [key: keyof typeof COMPONENTS]: ElementType }
): ReactNode {
  if (!node) return null;

  const getComponent = (type: keyof typeof COMPONENTS) => components?.[type] ?? COMPONENTS[type];

  const renderContent = () =>
    node.content?.map((contentNode) => (
      <Fragment key={uniqueId('richtext-')}>{serializeToJSX(contentNode, components)}</Fragment>
    )) ?? null;

  switch (node.type) {
    case 'horizontalRule':
    case 'hardBreak': {
      const Component = getComponent(node.type);
      return <Component />;
    }

    case 'button': {
      const Component = getComponent(node.type);
      return <Component href={node.attrs.href}>{renderContent()}</Component>;
    }

    case 'heading': {
      let Component = getComponent('paragraph');
      if (node.attrs.level === 2) Component = getComponent('title');
      if (node.attrs.level === 3) Component = getComponent('subtitle');
      return <Component>{renderContent()}</Component>;
    }

    case 'text': {
      let textNode = (
        <span
          dangerouslySetInnerHTML={{
            __html: sanitize(node.text, {
              allowedTags: ['iframe'],
              allowedAttributes: {
                iframe: [
                  'src',
                  'width',
                  'height',
                  'frameborder',
                  'allowfullscreen',
                  'loading',
                  'referrerpolicy',
                  'style',
                ],
              },
            }),
          }}
        />
      );

      node.marks?.forEach((mark) => {
        switch (mark.type) {
          case 'bold': {
            textNode = <strong>{textNode}</strong>;
            break;
          }

          case 'italic': {
            textNode = <em>{textNode}</em>;
            break;
          }

          case 'link': {
            textNode = <Link href={encodeURI(mark.attrs.href)}>{textNode}</Link>;
            break;
          }

          case 'underline': {
            textNode = <u>{textNode}</u>;
            break;
          }

          default: {
            break;
          }
        }
      });

      return textNode;
    }

    default: {
      const Component = getComponent(node.type);
      if (!Component) return null;
      return <Component>{renderContent()}</Component>;
    }
  }
}

export function serializeToText(node?: JSONContent | null): string {
  if (!node?.content) return node?.text ?? '';
  return node.content
    .map((contentNode) => serializeToText(contentNode).trim())
    .join(' ')
    .trim();
}
