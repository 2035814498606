import { useRef, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { faCircleCheck, faCircleExclamation } from '@fortawesome/pro-solid-svg-icons';

import config from 'config';

const DownloadListItem = ({ url, fileName, onComplete }) => {
  const isCSV = fileName.includes('.csv');
  const download = useRef(null);
  const [{ state, data }, setData] = useState({});

  const onFetch = useCallback(async () => {
    setData({ state: 'loading', data: null });

    try {
      const response = await fetch(url);
      const responseText = await response.text();

      setData({ state: 'success', data: responseText });
    } catch (err) {
      if (config('/debug')) console.error(err);
      setData({ state: 'error', data: err });
    } finally {
      setTimeout(() => onComplete(), 2500);
    }
  }, [url, onComplete]);

  useEffect(() => {
    if (!state) {
      onFetch();
      return;
    }

    if (state === 'success') {
      download.current.click();
    }
  }, [state, onFetch]);

  return (
    <div className="bg-gray-800 p-3 rounded-md shadow-lg text-white">
      {state === 'loading' && (
        <div className="flex items-center gap-2">
          <FontAwesomeIcon icon={faSpinner} className="text-green-500" size="md" spin />
          <p>Generating Your Download...</p>
        </div>
      )}
      {state === 'success' && (
        <div className="flex items-center gap-2">
          <FontAwesomeIcon icon={faCircleCheck} className="text-green-500" size="md" />
          <p>Download Complete</p>
        </div>
      )}
      {state === 'error' && (
        <div className="flex items-center gap-2">
          <FontAwesomeIcon icon={faCircleExclamation} className="text-red-500" size="md" />
          <p>There was an issue generating your report. Try again later.</p>
        </div>
      )}
      <a
        href={isCSV ? `data:text/csv;charset=utf-8,${encodeURIComponent(data)}` : url}
        download={fileName}
        ref={download}
        className="hidden"
      >
        Click to download
      </a>
    </div>
  );
};

DownloadListItem.propTypes = {
  url: PropTypes.string.isRequired,
  onComplete: PropTypes.func.isRequired,
  fileName: PropTypes.string,
};

DownloadListItem.defaultProps = {
  fileName: 'report.csv',
};

export default DownloadListItem;
