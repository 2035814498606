import { useMemo } from 'react';
import { useWindowSize } from 'rooks';

import config from 'config';
import useIsClient from 'hooks/useIsClient';

type ScreenSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

type ScreenResult = Record<ScreenSize, boolean> & { width: number };

const screens: Record<ScreenSize, string> = config('/tw/screens');

const useScreen = ({ ssrWidth = 320 } = {}): ScreenResult => {
  const { innerWidth } = useWindowSize();
  const isClient = useIsClient();

  return useMemo(() => {
    const result = { width: innerWidth } as Partial<ScreenResult>;
    const windowWidth = isClient ? innerWidth : ssrWidth;
    Object.entries(screens).forEach(([screen, maxWidth]) => {
      result[screen as ScreenSize] = windowWidth >= Number(maxWidth.replace('px', ''));
    });
    return result as ScreenResult;
  }, [isClient, ssrWidth, innerWidth]);
};

export default useScreen;
