import { gql } from '@apollo/client';
import isUrl from 'is-url';
import { isDate, isObject } from 'lodash';
import { DateTime } from 'luxon';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import * as yup from 'yup';

import config from '@/config';
import createApolloClient from '@/createApolloClient';
import { serializeToText } from '@/lib/serializeTiptap';

const SLUG_QUERY = gql`
  query SlugRoute($key: String!, $scope: SlugScopeEnum, $parentId: String) {
    getSlug(key: $key, scope: $scope, parentId: $parentId) {
      key
      type
      objectId
    }
  }
`;

const MESSAGES: Record<string, string> = config('/validationMessages');

const isEmpty = (x: unknown): x is null | undefined | '' => x == null || x === '';

export const makeNullable = <T extends yup.Schema>(validator: T): ReturnType<T['nullable']> =>
  validator.nullable().transform((value: unknown) => {
    if (isEmpty(value)) return null;
    if (isObject(value) && !isDate(value) && Object.values(value).every(isEmpty)) return null;
    return value;
  });

export const makeRequired = <T extends yup.Schema>(validator: T): ReturnType<T['required']> =>
  validator.required(MESSAGES.required);

export const optionalString = makeNullable(yup.string());
export const requiredString = makeRequired(yup.string());

export const optionalRichText = yup
  .object({
    type: yup.string().oneOf(['doc']),
    content: yup.array(),
  })
  .nullable()
  .transform((value) => {
    if (!value) return null;
    return serializeToText(value) ? value : null;
  });

export const requiredRichText = yup
  .object({
    type: yup.string().oneOf(['doc']).required(),
    content: yup.array(),
  })
  .test('has-content', MESSAGES.required, (value) => !!serializeToText(value))
  .required(MESSAGES.required);

export const color = yup.string().matches(/^#([0-9A-F]{3}){1,2}$/i, {
  excludeEmptyString: true,
  message: MESSAGES.color,
});

export const optionalColor = makeNullable(color);
export const requiredColor = makeRequired(color);

export const phone = yup
  .string()
  .test('is-phone', MESSAGES.phone, (value) => isEmpty(value) || isPossiblePhoneNumber(value));

export const optionalPhone = makeNullable(phone);
export const requiredPhone = makeRequired(phone);

export const email = yup.string().email(MESSAGES.email).trim();
export const optionalEmail = makeNullable(email);
export const requiredEmail = makeRequired(email);

export const date = yup
  .string()
  .test('is-date', MESSAGES.date, (value) => isEmpty(value) || DateTime.fromISO(value).isValid)
  // Ensure we're only ever keeping the date string (without time)
  .transform((value) => (value ? DateTime.fromISO(value).toISODate() : ''));
export const optionalDate = makeNullable(date);
export const requiredDate = makeRequired(date);

export const datetime = yup
  .string()
  .test('is-date', MESSAGES.date, (value) => isEmpty(value) || DateTime.fromISO(value).isValid)
  // Ensure we're only ever keeping the date string (without time)
  .transform((value) => (value ? DateTime.fromISO(value).toISO() : ''));
export const optionalDatetime = makeNullable(datetime);
export const requiredDatetime = makeRequired(datetime);

export const number = yup
  .number()
  .transform((value, originalValue) => (originalValue === '' ? null : value))
  .typeError(MESSAGES.number);
export const optionalNumber = makeNullable(number);
export const requiredNumber = makeRequired(number);

export const currency = yup
  .number()
  .transform((value, originalValue) => (originalValue === '' ? null : value))
  .typeError(MESSAGES.currency);
export const optionalCurrency = makeNullable(currency);
export const requiredCurrency = makeRequired(currency);

export const currencyString = yup.string().matches(/^\d+(\.\d{0,2})?$/, MESSAGES.currency);
export const optionalCurrencyString = makeNullable(currencyString);
export const requiredCurrencyString = makeRequired(currencyString);

// Only allows full URLs (starting with http)
export const url = yup
  .string()
  .test('is-url', MESSAGES.url, (value) => isEmpty(value) || isUrl(value));
export const optionalUrl = makeNullable(url);
export const requiredUrl = makeRequired(url);

// Allows URLs and absolute links
export const link = yup
  .string()
  .test(
    'is-link',
    MESSAGES.url,
    (value) => isEmpty(value) || isUrl(value) || value.startsWith('/')
  );
export const optionalLink = makeNullable(link);
export const requiredLink = makeRequired(link);

export const slug = yup
  .string()
  .min(3, 'Must be a minimum of 3 characters in length')
  .max(64, 'Must be less than 64 characters')
  .matches(/^[0-9a-z-]+$/i, {
    message: 'This field can only contain letters, numbers, hyphens, and may not contain spaces.',
    excludeEmptyString: true,
  });

export const addCustomValidators = () => {
  yup.addMethod(yup.string, 'minDate', function minDateString(minDate, message, inclusive = true) {
    return this.test({
      name: 'minDate',
      message: message ?? `Date cannot be before ${date}`,
      test(value) {
        if (isEmpty(value)) return true;
        if (inclusive) return DateTime.fromISO(value) >= DateTime.fromISO(this.resolve(minDate));
        return DateTime.fromISO(value) > DateTime.fromISO(this.resolve(minDate));
      },
    });
  });

  yup.addMethod(yup.string, 'maxDate', function maxDateString(maxDate, message) {
    return this.test({
      name: 'maxDate',
      message: message ?? `Date cannot be after ${date}`,
      test(value) {
        if (isEmpty(value)) return true;
        return DateTime.fromISO(value) <= DateTime.fromISO(this.resolve(maxDate));
      },
    });
  });

  yup.addMethod(yup.string, 'futureDate', function futureDateString(message) {
    return this.test({
      name: 'futureDate',
      message: message ?? 'Date must be in the future',
      test(value) {
        if (isEmpty(value)) return true;
        return DateTime.fromISO(value) > DateTime.local().endOf('day');
      },
    });
  });

  yup.addMethod(
    yup.string,
    'futureDateTime',
    function futureDateTimeString(timeValue, timezone, message) {
      return this.test({
        name: 'futureDateTime',
        message: message ?? 'Date must be in the future',
        test(value) {
          if (isEmpty(value)) return true;
          if (isEmpty(timeValue)) return false;
          const [hour, minute] = timeValue.split(':') ?? [0, 0];
          const toTest = DateTime.fromISO(value, { zone: timezone }).set({
            hour: parseInt(hour),
            minute: parseInt(minute),
          });
          return toTest > DateTime.now().setZone(timezone);
        },
      });
    }
  );

  yup.addMethod(
    yup.string,
    'uniqueSlug',
    function uniqueSlug({ scope, objectId, parentId, message }) {
      return this.test({
        name: 'uniqueSlug',
        message: message ?? 'That vanity URL is already in use',
        async test(value) {
          // @ts-expect-error createApolloClient has not yet been converted to TS
          const client = createApolloClient({});
          if (value) {
            const slugQuery = await client.query({
              query: SLUG_QUERY,
              variables: { key: value, scope, parentId },
            });

            const slugResponse = slugQuery?.data?.getSlug;
            return !slugResponse || slugResponse?.objectId === objectId;
          }
          return true;
        },
      });
    }
  );
};
