import Store from 'conf-store';
import camelCaseKeys from 'lib/camelCaseKeys';
import tailwindTheme from './tailwind-theme.preval';

const env = process.env.NEXT_PUBLIC_APP_ENV || 'development';

const store = new Store({
  apollo: {
    http: {
      uri: {
        $filter: 'env',
        staging: 'https://mako.pledgeit.org/graphql',
        production: 'https://charity.pledgeit.org/graphql',
        $default: process.env.NEXT_PUBLIC_GRAPHQL_URI || 'http://localhost:3000/graphql',
      },
    },
    ws: {
      uri: {
        $filter: 'env',
        staging: 'wss://mako.pledgeit.org/graphql',
        production: 'wss://charity.pledgeit.org/graphql',
        $default: process.env.NEXT_PUBLIC_GRAPHQLWS_URI || 'ws://localhost:3000/graphql',
      },
    },
  },

  cloudinary: {
    cloudName: 'pledge-it',
  },

  debug: env !== 'production',

  defaultCampaignHero: 'v1681996009/mako/application/campaign-poly-hero.jpg',
  defaultFundraiserAvatar: 'v1593449872/mako/application/default-fundraiser-icon.svg',
  defaultFundraiserHeaderImage: 'v1593449872/mako/application/default-individual-cover.svg',
  defaultPlaceholderImage: 'v1603778075/mako/application/empty-placeholder.png',
  defaultTeamAvatar: 'v1593449872/mako/application/default-team-icon.svg',
  defaultTeamHeaderImage: 'v1593449872/mako/application/default-team-cover.svg',
  defaultAuctionItemImage: 'v1689364994/default-auction-item_y3eabv.png',

  // Store some handy global values
  dimensions: {
    menuWidth: {
      closed: 80,
      open: 375,
    },
  },

  embedWidgets: {
    scriptPath: {
      $filter: 'env',
      production: 'https://assets.pledgeit.org/embed/v1/wc-embed.js',
      $default: 'https://assets.pledgeit.org/embed/dev/wc-embed.js',
    },
  },

  env,

  google: {
    analyticsTrackingId: 'G-MWF5L8NK93',
    reCaptchaKey: '6LdKYDklAAAAAPUknEbSl5-nmjUmSmh_E1sdmKJi',
  },

  hostname: {
    $filter: 'env',
    staging: 'https://mako.pledgeit.org',
    production: 'https://charity.pledgeit.org',
    $default: process.env.NEXT_PUBLIC_HOSTNAME || 'http://localhost:3000',
  },

  hotjar: {
    id: {
      $filter: 'env',
      staging: 2441655,
      production: 2420422,
      $default: undefined,
    },
    version: 6,
  },

  iframely: {
    key: '87c39be8d09dc8f6b4f63d9309e83452',
  },

  seo: {
    titleTemplate: '%s | PledgeIt for Charities',
    title: 'Fans Pledge. Teams Perform. Everyone Wins!',
    description:
      'Have fun raising money by asking your family, friends and fans to pledge their support based on your team’s performance. Turn those pledges into donations with every touchdown, goal or run that you score!',
    openGraph: {
      type: 'website',
      url: {
        $filter: 'env',
        staging: 'https://mako.pledgeit.org',
        production: 'https://charity.pledgeit.org',
        $default: process.env.NEXT_PUBLIC_HOSTNAME || 'http://localhost:3000',
      },
      site_name: 'PledgeIt for Charities',
      images: [
        {
          url: 'https://res.cloudinary.com/pledge-it/image/upload/t_facebook/v1742525177/facebook-default.png',
        },
      ],
    },
    facebook: {
      appId: {
        $filter: 'env',
        production: '1480030898990902',
        $default: '1576987322628592',
      },
    },
    twitter: {
      site: '@pledgeit',
      cardType: 'summary_large_image',
    },
  },

  sentry: {
    debug: !!process.env.SENTRY_DEBUG,
    dsn: 'https://69c6b29adf234f2f815f90dfef6a9244@o260367.ingest.sentry.io/4503943006650368',
    environment: env,
    enabled: env !== 'development',
    tracesSampleRate: env === 'production' ? 0.1 : 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  },

  stripe: {
    defaultProviderOptions: {
      currency: 'usd',
      paymentMethodTypes: ['card'],
      appearance: {
        variables: {
          colorPrimary: '#186B74',
          gridRowSpacing: '24px',
        },
        rules: {
          '.Label': {
            paddingBottom: '8px',
          },
          '.Input:hover': {
            borderColor: '#757B87',
          },
        },
      },
    },
  },

  tw: camelCaseKeys(tailwindTheme, { deep: true }),

  urls: {
    privacyPolicy: 'https://pledgeit.org/privacy-policy',
    termsOfService: 'https://pledgeit.org/terms-of-service',
  },

  validationMessages: {
    required: 'This field is required',
    email: 'Invalid email address',
    phone: 'Invalid phone number',
    number: 'Must be a number',
    currency: 'Must be a dollar amount',
    positiveNumber: 'Must be greater than 0',
    color: 'Must be a hex color (e.g. #000000)',
    url: 'Must be a valid URL',
    date: 'Invalid date',
  },
});

const config = (key, criteria = {}) => store.get(key, { env, ...criteria });
export default config;
